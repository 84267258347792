import ProductCoreModel from '@/modules/products/models/ProductCoreModel';
import ORMModelExtended from '@/shared/lib/api/ORMModelExtended';

export default class ProductsListItemCoreModel extends ORMModelExtended {
  public static entity = 'products-list-items';

  public static apiConfig = {
    actions: {
      fetch: {
        method: 'GET',
        url: '/v2/products-lists/:listId/products',
      },
    },
  };

  public static fields() {
    return {
      id: this.number(null).nullable(),
      listId: this.number(null).nullable(),
      productId: this.number(null),
      quantity: this.number(1),
      product: this.belongsTo(ProductCoreModel, 'productId'),
    };
  }

  public id!: number;
  public listId!: number | null;
  public productId!: number;
  public quantity!: number;

  public product!: ProductCoreModel;
}
